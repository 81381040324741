import { useCallback, useEffect, useRef, useState } from "react";

import axios from "axios";

import {
  Autocomplete,
  Grid,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  IEmployeesAnalyticsFilters,
  IEmployeesAnalytics,
  IEmployeesAnalyticsEmployee,
} from "../../../types/analytics";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { renderTooltipCell } from "../../../helpers/grid";

import Helper from "../../../components/Helper";
import { EmployeesAnalyticsHelper } from "../../../constants/helpers";
import GridToolbar from "../../../components/Grid/Toolbar";
import xlsx, { IJsonSheet } from "json-as-xlsx";

interface IQueryOptions {
  userId?: number;
  channelId?: number;
  contentId?: number;
}

const EmployeesAnalytics: React.FunctionComponent<{}> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IEmployeesAnalytics>();
  const [filterData, setFilterData] = useState<IEmployeesAnalyticsFilters>();

  const gridRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [queryOptions, setQueryOptions] = useState<IQueryOptions>({});

  const handleXLSXExport = useCallback(() => {
    if (!data) {
      return;
    }

    const exportData: IJsonSheet[] = [
      {
        sheet: "Employees",
        columns: [
          {
            label: "Employee",
            value: "employeeName",
          },
          {
            label: "Team",
            value: "channelName",
          },
          {
            label: "Content",
            value: "contentName",
          },
          {
            label: "Sent lessons",
            value: "quizzesSent",
          },
          {
            label: "Completed lessons",
            value: "quizzesCompleted",
          },
          {
            label: "Completed lessons %",
            value: "quizzesCompletedPercent",
          },
          {
            label: "Correct lessons",
            value: "quizzesCorrect",
          },
          {
            label: "Correct lessons %",
            value: "quizzesCorrectPercent",
          },
        ],
        // @ts-ignore
        content: data.employeeList,
      },
    ];

    xlsx(exportData, {
      fileName: "employees-analytics",
    });
  }, [data]);

  useEffect(() => {
    axios
      .get<IEmployeesAnalyticsFilters>("/Alytics/GetEmployeeFilter")
      .then((res) => {
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<IEmployeesAnalytics>("/Alytics/GetEmployeeAnalytics", {
        params: {
          ...queryOptions,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [queryOptions]);

  const columns: GridColDef[] = [
    {
      field: "employeeName",
      headerName: "Employee",
      sortable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "channelName",
      headerName: "Team",
      flex: 1,
      sortable: false,
      minWidth: 200,
    },
    {
      field: "contentName",
      headerName: "Content",
      sortable: false,
      flex: 1,
      renderCell: renderTooltipCell,
      minWidth: 200,
    },
    {
      field: "quizzesSent",
      headerName: "Sent lessons",
      sortable: false,
      filterable: false,
      minWidth: 200,
    },
    {
      field: "quizzesCompleted",
      headerName: "Completed lessons",
      sortable: false,
      renderCell: (params) => {
        return `${params.value} (${params.row.quizzesCompletedPercent} %)`;
      },
      filterable: false,
      minWidth: 240,
    },
    {
      field: "quizzesCorrect",
      headerName: "Correct lessons",
      sortable: false,
      renderCell: (params) => {
        return `${params.value} (${params.row.quizzesCorrectPercent} %)`;
      },
      filterable: false,
      minWidth: 200,
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Employees analytics
            </Typography>
            <Helper title={<EmployeesAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>

      <DataGrid
        ref={gridRef}
        disableColumnFilter
        getRowId={(row) =>
          `${row.userId}-${row.channelId}-${row.questionCategoryId}`
        }
        rows={[
          ...(data ? data.employeeList : []),
          {
            userId: 0,
            employeeName: "",
            channelId: 0,
            channelName: "",
            contentName: "Total:",
            quizzesSent: data ? data.totalQuizzesSent : 0,
            quizzesCompleted: data ? data.totalQuizzesCompleted : 0,
            quizzesCompletedPercent: data
              ? data.totalQuizzesCompletedPercent
              : 0,
            quizzesCorrect: data ? data.totalQuizzesCorrect : 0,
            quizzesCorrectPercent: data ? data.totalQuizzesCorrectPercent : 0,
          } as IEmployeesAnalyticsEmployee,
        ]}
        rowCount={data?.numberOfEntries}
        components={{
          Toolbar: () => {
            return (
              <GridToolbar
                filterCount={
                  Object.values(queryOptions).filter((item) => item).length
                }
                toggleFilter={() => setIsFilterOpen(true)}
                onExportXLSX={handleXLSXExport}
              />
            );
          },
        }}
        columns={columns}
        loading={isLoading}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        pageSizeOptions={[100]}
      />
      <Popover
        open={isFilterOpen}
        anchorEl={gridRef.current}
        onClose={() => setIsFilterOpen(false)}
        style={{
          marginTop: 40,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
          <Typography variant="h6">Filters</Typography>
          <Autocomplete
            options={filterData ? filterData.userList : []}
            value={
              filterData
                ? filterData.userList.find(
                    (item) => item.id === queryOptions.userId
                  )
                : null
            }
            getOptionLabel={(option) => option.displayName}
            renderInput={(params) => <TextField {...params} label="Employee" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                userId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.channelList : []}
            value={
              filterData
                ? filterData.channelList.find(
                    (item) => item.id === queryOptions.channelId
                  )
                : null
            }
            getOptionLabel={(option) => option.teamName}
            renderInput={(params) => <TextField {...params} label="Team" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                channelId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.contentList : []}
            value={
              filterData
                ? filterData.contentList.find(
                    (item) => item.id === queryOptions.contentId
                  )
                : null
            }
            getOptionLabel={(option) => option.contentName}
            renderInput={(params) => <TextField {...params} label="Content" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                contentId: value ? value.id : undefined,
              }));
            }}
          />
        </Stack>
      </Popover>
    </Stack>
  );
};

export default EmployeesAnalytics;
