import React from "react";

// constants & types
import MODALS from "../constants/modals";

// utils
import { useAppSelector } from "../hooks/redux";

// modals
import ApproveModal from "../modals/Approve";
import CreateContentModal from "../modals/CreateContent";
import CreateChallengeModal from "../modals/CreateChallenge";
import SendAnnouncement from "../modals/SendAnnouncement";
import ViewAnnouncement from "../modals/ViewAnnouncement";
import UploadContent from "../modals/UploadContent";
import AddUser from "../modals/AddUser";
import CreateTeam from "../modals/CreateTeam";
import TeamTypeModal from "../modals/TeamType";
import CreateTest from "../modals/CreateTest";

const ModalManager: React.FunctionComponent<{}> = () => {
  const modals = useAppSelector((state) => state.modals);

  return modals.length === 0 ? null : (
    <>
      {modals.map((modal) => {
        switch (modal.type) {
          case MODALS.Approve:
            return <ApproveModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.CreateContent:
            return <CreateContentModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.CreateChallenge:
            return (
              <CreateChallengeModal key={`modal_${modal.id}`} {...modal} />
            );
          case MODALS.SendAnnouncement:
            return <SendAnnouncement key={`modal_${modal.id}`} {...modal} />;
          case MODALS.ViewAnnouncement:
            return <ViewAnnouncement key={`modal_${modal.id}`} {...modal} />;
          case MODALS.UploadContent:
            return <UploadContent key={`modal_${modal.id}`} {...modal} />;
          case MODALS.AddUser:
            return <AddUser key={`modal_${modal.id}`} {...modal} />;
          case MODALS.CreateTeam:
            return <CreateTeam key={`modal_${modal.id}`} {...modal} />;
          case MODALS.TeamType:
            return <TeamTypeModal key={`modal_${modal.id}`} {...modal} />;
          case MODALS.CreateTest:
            return <CreateTest key={`modal_${modal.id}`} {...modal} />;
          default:
            console.error("Undefined modal component");
            return null;
        }
      })}
    </>
  );
};

export default ModalManager;
