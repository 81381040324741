import { useCallback, useEffect, useRef, useState } from "react";

import axios from "axios";

import { Link as RouterLink } from "react-router-dom";

import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import {
  ITestAnalyticsFilters,
  ITestAnalytics,
  ITestAnalyticsEmployee,
} from "../../../types/analytics";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { useLocation } from "react-router-dom";
import { GeneralAnalyticsCard } from "./Content";

import GridToolbar from "../../../components/Grid/Toolbar";
import moment from "moment";
import Helper from "../../../components/Helper";
import { TestsAnalyticsHelper } from "../../../constants/helpers";

import noChallenges from "../../../assets/img/no_challenges.svg";
import xlsx, { IJsonSheet } from "json-as-xlsx";
import { ITestResponse } from "../../../types/test";

interface IQueryOptions {
  userId?: number;
  onTarget?: boolean;
}

const TestsAnalytics: React.FC = () => {
  const locationState = useLocation().state as {
    testId?: number;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [tests, setTests] = useState<ITestResponse[]>([]);
  const [selectedTestId, setSelectedTestId] = useState<number | undefined>(locationState?.testId);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState<ITestAnalytics>();
  const [filterData, setFilterData] = useState<ITestAnalyticsFilters>();

  const gridRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [queryOptions, setQueryOptions] = useState<IQueryOptions>({});

  const handleXLSXExport = useCallback(() => {
    if (!data) {
      return;
    }

    const exportData: IJsonSheet[] = [
      {
        sheet: "Tests",
        columns: [
          {
            label: "Employee",
            value: "userName",
          },
          {
            label: "Correct answers",
            value: "quizzesCorrect",
          },
          {
            label: "Correct answers %",
            value: "quizzesCorrectPercent",
          },
        ],
        // @ts-ignore
        content: data.employeeData,
      },
    ];

    xlsx(exportData, {
      fileName: "tests-analytics",
    });
  }, [data]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<{
        tests: ITestResponse[];
      }>(`/Test/GetTests`)
      .then((res) => {
        setSelectedTestId((prevState) => (!prevState ? res.data.tests[0]?.id : prevState));
        setTests(res.data.tests);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getTetsFilters = useCallback(() => {
    if (!selectedTestId) {
      return;
    }
    axios
      .get<ITestAnalyticsFilters>("/Alytics/GetTestFilter", {
        params: {
          testId: selectedTestId,
        },
      })
      .then((res) => {
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, [selectedTestId]);

  useEffect(() => {
    getTetsFilters();
  }, [getTetsFilters]);

  const loadData = useCallback(() => {
    if (!selectedTestId) {
      return;
    }
    setIsDataLoading(true);
    axios
      .get<ITestAnalytics>("/Alytics/GetTestAlytics", {
        params: {
          ...queryOptions,
          testId: selectedTestId,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [selectedTestId, queryOptions]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns: GridColDef<ITestAnalyticsEmployee>[] = [
    {
      field: "userName",
      headerName: "Employee",
      sortable: false,
      flex: 1,
    },
    {
      field: "quizzesCorrectPercent",
      headerName: "Correct answers",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography color={params.row.quizzesCorrectCriteriaMet ? "success" : "error"}>
            {params.row.quizzesCorrectPercent}% ({params.row.quizzesCorrect}/
            {params.row.quizzesSent})
          </Typography>
        );
      },
      minWidth: 210,
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Test analytics
            </Typography>
            {tests.length > 0 && (
              <Select
                style={{
                  marginLeft: 16,
                }}
                key={tests.length}
                variant="filled"
                onChange={(event: SelectChangeEvent) => {
                  setSelectedTestId(parseInt(event.target.value));
                }}
                value={selectedTestId?.toString() || ""}
                disableUnderline
                displayEmpty
                renderValue={(value) => {
                  if (!value) {
                    return <Typography>Select test</Typography>;
                  }

                  const test = tests.find((item) => item.id === parseInt(value));

                  return (
                    <Typography>
                      {test?.questionCategoryName} in team {test?.channelName}
                    </Typography>
                  );
                }}
              >
                {tests.map((challenge) => (
                  <MenuItem key={challenge.id} value={challenge.id}>
                    <Stack>
                      <Typography>
                        {challenge.questionCategoryName} in team {challenge.channelName}
                      </Typography>
                      <Typography variant="caption">
                        {moment.utc(challenge.startDate).format("L")} -{" "}
                        {moment.utc(challenge.endDate).format("L")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            )}
            <Helper title={<TestsAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>

      {!isLoading && tests.length === 0 && (
        <Stack spacing={2} alignItems="center">
          <Typography variant="h3" component="h1">
            No tests created.
          </Typography>
          <Typography>
            To see the analytics, please{" "}
            <Link component={RouterLink} to="/tests" underline="none">
              create your first test
            </Link>
            .
          </Typography>
          <img
            src={noChallenges}
            alt="No tests created"
            style={{
              width: "100%",
              maxWidth: 520,
            }}
          />
        </Stack>
      )}

      {isLoading && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}

      {!isLoading && tests.length > 0 && (
        <Stack spacing={2}>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <GeneralAnalyticsCard
                  title="Number of participants"
                  primaryText={data?.numberOfParticipants.toString() || "0"}
                  loading={!data}
                />
              </Grid>
              <Grid item xs={4}>
                <GeneralAnalyticsCard
                  title="Users completed test"
                  primaryText={data?.usersCompletedTest.toString() || "0"}
                  loading={!data}
                />
              </Grid>
            </Grid>
          </div>
          <Typography variant="h3">Employees</Typography>
          <DataGrid
            ref={gridRef}
            disableColumnFilter
            getRowId={(row) => row.userId}
            rows={data?.employeeData || []}
            rowCount={data?.numberOfEntries || 0}
            components={{
              Toolbar: () => {
                return (
                  <GridToolbar
                    filterCount={
                      Object.values(queryOptions).filter((item) => item || item === false).length
                    }
                    toggleFilter={() => setIsFilterOpen(true)}
                    onExportXLSX={handleXLSXExport}
                  />
                );
              },
            }}
            columns={columns}
            loading={isDataLoading}
            autoHeight
            disableRowSelectionOnClick
            disableColumnMenu
            pageSizeOptions={[100]}
          />
          <Popover
            open={isFilterOpen}
            anchorEl={gridRef.current}
            onClose={() => setIsFilterOpen(false)}
            style={{
              marginTop: 40,
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
              <Typography variant="h6">Filters</Typography>
              <Autocomplete
                options={filterData ? filterData.users : []}
                value={
                  filterData
                    ? filterData.users.find((item) => item.id === queryOptions.userId)
                    : null
                }
                getOptionLabel={(option) => option.displayName}
                renderInput={(params) => <TextField {...params} label="Employee" />}
                onChange={(e, value) => {
                  setQueryOptions((prev) => ({
                    ...prev,
                    userId: value ? value.id : undefined,
                  }));
                }}
              />
              <FormControlLabel
                label="On target"
                control={
                  <Checkbox
                    checked={queryOptions.onTarget}
                    indeterminate={queryOptions.onTarget === undefined}
                    onChange={(e) => {
                      let value: boolean | undefined = undefined;

                      // switch three states
                      if (queryOptions.onTarget === undefined) {
                        value = true;
                      } else if (queryOptions.onTarget === true) {
                        value = false;
                      }

                      setQueryOptions((prev) => ({
                        ...prev,
                        onTarget: value,
                      }));
                    }}
                  />
                }
              />
            </Stack>
          </Popover>
        </Stack>
      )}
    </Stack>
  );
};

export default TestsAnalytics;
