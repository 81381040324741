import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router";

import {
  Grid,
  IconButton,
  Stack,
  Chip,
  Typography,
  Link,
  Tooltip,
  Button,
} from "@mui/material";
import { ITeam, ITeamResponse } from "../../types/team";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";

import { track } from "@amplitude/analytics-browser";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import WarningIcon from "@mui/icons-material/Warning";
import Helper from "../../components/Helper";
import { TeamsHelper } from "../../constants/helpers";
import Edit from "../../components/Icons/Edit";
import Plus from "../../components/Icons/Plus";
import { openModal } from "../../features/modals";
import MODALS from "../../constants/modals";
import { nextStep, setRun } from "../../features/tour";

const Teams = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const tour = useAppSelector((state) => state.tour);
  const languages = useAppSelector((state) => state.classifiers.languages);

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<ITeamResponse>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const loadData = useCallback(() => {
    setLoading(true);
    axios
      .get<ITeamResponse>("/Teams/GetTeams", {
        params: { page: paginationModel.page + 1 },
      })
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paginationModel.page]);

  useEffect(() => {
    loadData();
    track("OpenedTeamsView");
  }, [loadData]);

  useEffect(() => {
    if (data) {
      if (tour.index === 8 || tour.index === 9) {
        if (data.teamsData.length > 0 && tour.index === 8) {
          dispatch(
            nextStep({
              stop: false,
            })
          );
        }

        dispatch(setRun(true));
      } else if (tour.index === 11 && tour.run === false) {
        const team = data.teamsData[0];
        if (team) {
          navigate(`/teams/${team.channelId}/${team.language}`);
        }
      }
    }
  }, [tour, data]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Team",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<ITeam>) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Link
              component={RouterLink}
              to={`/teams/${params.row.channelId}/${params.row.language}`}
            >
              <Typography>{params.value}</Typography>
            </Link>
            {(params.row.quizzesUnavailable || params.row.lowSocialLessons) && (
              <Tooltip title="One or more of the courses assigned to this team does not have enough lessons. This might lead to some team members not receiving their daily lessons.">
                <WarningIcon color="error" />
              </Tooltip>
            )}
          </Stack>
        );
      },
      minWidth: 200,
    },
    {
      field: "members",
      headerName: "Members",
      sortable: false,
      minWidth: 130,
    },
    {
      field: "language",
      headerName: "Language",
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        const lang = languages.find(
          (language) => language.languageId === params.value
        );

        return lang ? lang.languageName : "";
      },
      minWidth: 130,
    },
    {
      field: "content",
      headerName: "Content",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string[]>) => (
        <Stack direction="row" spacing={1}>
          {params.value &&
            params.value.map((row: string, index: number) => (
              <Chip key={index} label={row} />
            ))}
        </Stack>
      ),
      minWidth: 130,
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 60,
      renderCell: (params) => {
        return (
          <Link
            className="edit-team-button"
            component={RouterLink}
            to={`/teams/${params.row.channelId}/${params.row.language}`}
          >
            <IconButton>
              <Edit />
            </IconButton>
          </Link>
        );
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Schedule Courses
            </Typography>
            <Helper title={<TeamsHelper />} />
          </Stack>
        </Grid>
        <Grid item>
          <Button
            className="create-team-button"
            variant="contained"
            startIcon={<Plus color="#fff" />}
            onClick={() => {
              dispatch(
                openModal({
                  type: MODALS.TeamType,
                })
              );
            }}
          >
            Create team
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        getRowId={(row) => row.channelId}
        rows={data ? data.teamsData : []}
        rowCount={data ? data.numberOfEntries : 0}
        columns={columns}
        loading={isLoading}
        slotProps={{}}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[100]}
        paginationMode="server"
      />
    </Stack>
  );
};

export default Teams;
