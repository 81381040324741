import { useCallback, useEffect, useRef, useState } from "react";

import axios from "axios";
import xlsx, { IJsonSheet } from "json-as-xlsx";

import { LIST as DIFFICULTIES_LIST } from "../../../constants/dificulties";
import { Autocomplete, Grid, Popover, Stack, TextField, Typography } from "@mui/material";
import {
  IEmployeesKnowledgeAnalyticsResponse,
  IEmployeesKnowledgeFilters,
} from "../../../types/analytics";
import {
  DataGrid,
  getGridSingleSelectOperators,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import moment from "moment";
import { renderTooltipCell } from "../../../helpers/grid";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveIcon from "@mui/icons-material/Remove";
import Helper from "../../../components/Helper";
import { LessonsAnalyticsHelper } from "../../../constants/helpers";
import GridToolbar from "../../../components/Grid/Toolbar";

interface IQueryOptions {
  userId?: number;
  channelId?: number;
  questionCategoryId?: number;
  questionId?: number;
  topic?: string;
  difficulty?: number;
}

const QuizzesAnalytics: React.FunctionComponent<{}> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IEmployeesKnowledgeAnalyticsResponse>();
  const [filterData, setFilterData] = useState<IEmployeesKnowledgeFilters>();

  const gridRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [queryOptions, setQueryOptions] = useState<IQueryOptions>({});

  const handleXLSXExport = useCallback(() => {
    if (!data) {
      return;
    }

    const exportData: IJsonSheet[] = [
      {
        sheet: "Lessons",
        columns: [
          { label: "Employee", value: "employeeName" },
          { label: "Content", value: "contentName" },
          { label: "Question", value: "question" },
          { label: "Topic", value: "topic" },
          {
            label: "Difficulty",
            value: (row) => {
              const difficulty = DIFFICULTIES_LIST.find(
                (difficulty) => difficulty.value === row.value
              );
              if (!difficulty) {
                return null;
              }
              return `${difficulty.value} - ${difficulty.name}`;
            },
          },
          { label: "Team", value: "teamName" },
          {
            label: "Time sent",
            // @ts-ignore
            value: (value) => moment.utc(value.timeSent).local().toDate(),
            format: "dd.mm.yyyy hh:mm",
          },
          {
            label: "Time answered",
            value: (value) =>
              // @ts-ignore
              value.timeAnswered ? moment.utc(value.timeAnswered).local().toDate() : "",
            format: "dd.mm.yyyy hh:mm",
          },
          { label: "Answer given", value: "answerGiven" },
          { label: "Answer correct", value: "answerCorrect" },
        ],
        // @ts-ignore
        content: data.employeeKnowledge,
      },
    ];

    xlsx(exportData, {
      fileName: "lessons-analytics",
    });
  }, [data]);

  useEffect(() => {
    axios
      .get<IEmployeesKnowledgeFilters>("/Alytics/GetEmployeeKnowledgeFilter")
      .then((res) => {
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get<IEmployeesKnowledgeAnalyticsResponse>("/Alytics/GetEmployeeKnowledge", {
        params: {
          ...queryOptions,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [queryOptions]);

  const columns: GridColDef[] = [
    {
      field: "employeeName",
      headerName: "Employee",
      sortable: false,
      flex: 1,
      valueOptions: filterData
        ? filterData.userList.map((item) => ({ value: item.id, label: item.displayName }))
        : [],
      filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === "is"),
      minWidth: 200,
    },
    {
      field: "contentName",
      headerName: "Content",
      sortable: false,
      flex: 1,
      valueOptions: filterData
        ? filterData.contentList.map((item) => ({ value: item.id, label: item.contentName }))
        : [],
      filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === "is"),
      renderCell: renderTooltipCell,
      minWidth: 200,
    },
    {
      field: "question",
      headerName: "Question",
      sortable: false,
      flex: 1,
      valueOptions: filterData
        ? filterData.questionList.map((item) => ({ value: item.id, label: item.questionText }))
        : [],
      filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === "is"),
      renderCell: renderTooltipCell,
      minWidth: 200,
    },
    {
      field: "topic",
      headerName: "Topic",
      sortable: false,
      flex: 1,
      valueOptions: filterData
        ? filterData.topicList.map((item) => ({ value: item.topicText, label: item.topicText }))
        : [],
      filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === "is"),
      renderCell: renderTooltipCell,
      minWidth: 200,
    },
    {
      field: "difficulty",
      headerName: "Difficulty",
      sortable: false,
      valueOptions: filterData
        ? filterData.difficultyList.map((item) => ({
            value: item.difficultyValue,
            label: item.difficultyText,
          }))
        : [],
      filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === "is"),
      renderCell: (params) => {
        const difficulty = DIFFICULTIES_LIST.find(
          (difficulty) => difficulty.value === params.value
        );
        if (!difficulty) {
          return null;
        }
        return `${difficulty.value} - ${difficulty.name}`;
      },
      minWidth: 200,
    },
    {
      field: "teamName",
      headerName: "Team",
      sortable: false,
      flex: 1,
      valueOptions: filterData
        ? filterData.channelList.map((item) => ({ value: item.id, label: item.teamName }))
        : [],
      filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === "is"),
      minWidth: 150,
    },
    {
      field: "timeSent",
      headerName: "Time sent",
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        moment
          .utc(params.value as Date)
          .local()
          .format("L LT"),
      filterable: false,
      renderCell: renderTooltipCell,
      minWidth: 200,
    },
    {
      field: "timeAnswered",
      headerName: "Time answered",
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value
          ? moment
              .utc(params.value as Date)
              .local()
              .format("L LT")
          : "",
      filterable: false,
      renderCell: renderTooltipCell,
      minWidth: 200,
    },
    {
      field: "answerGiven",
      headerName: "Answer given",
      sortable: false,
      flex: 1,
      filterable: false,
      renderCell: renderTooltipCell,
      minWidth: 200,
    },
    {
      field: "answerCorrect",
      headerName: "Answer correct",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        switch (params.value) {
          case undefined:
            return <RemoveIcon />;
          case true:
            return <CheckIcon color="success" />;
          default:
            return <ClearIcon color="error" />;
        }
      },
      minWidth: 200,
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Lessons analytics
            </Typography>
            <Helper title={<LessonsAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>

      <DataGrid
        ref={gridRef}
        getRowId={(row) => row.quizId}
        components={{
          Toolbar: () => {
            return (
              <GridToolbar
                filterCount={Object.values(queryOptions).filter((item) => item).length}
                toggleFilter={() => setIsFilterOpen(true)}
                onExportXLSX={handleXLSXExport}
              />
            );
          },
        }}
        rows={data ? data.employeeKnowledge : []}
        rowCount={data?.numberOfEntries}
        columns={columns}
        loading={isLoading}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        pageSizeOptions={[100]}
      />
      <Popover
        open={isFilterOpen}
        anchorEl={gridRef.current}
        onClose={() => setIsFilterOpen(false)}
        style={{
          marginTop: 40,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
          <Typography variant="h6">Filters</Typography>
          <Autocomplete
            options={filterData ? filterData.userList : []}
            value={
              filterData
                ? filterData.userList.find((item) => item.id === queryOptions.userId)
                : null
            }
            getOptionLabel={(option) => option.displayName}
            renderInput={(params) => <TextField {...params} label="Employee" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                userId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.channelList : []}
            value={
              filterData
                ? filterData.channelList.find((item) => item.id === queryOptions.channelId)
                : null
            }
            getOptionLabel={(option) => option.teamName}
            renderInput={(params) => <TextField {...params} label="Team" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                channelId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.contentList : []}
            value={
              filterData
                ? filterData.contentList.find((item) => item.id === queryOptions.questionCategoryId)
                : null
            }
            getOptionLabel={(option) => option.contentName}
            renderInput={(params) => <TextField {...params} label="Content" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                questionCategoryId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={
              filterData
                ? filterData.questionList.filter((item) =>
                    queryOptions.questionCategoryId
                      ? item.questionCategoryId === queryOptions.questionCategoryId
                      : true
                  )
                : []
            }
            value={
              filterData
                ? filterData.questionList.find((item) => item.id === queryOptions.questionId)
                : null
            }
            getOptionLabel={(option) => option.questionText}
            renderInput={(params) => <TextField {...params} label="Question" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                questionCategoryId: value ? value.questionCategoryId : prev.questionCategoryId,
                questionId: value ? value.id : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.topicList : []}
            value={
              filterData
                ? filterData.topicList.find((item) => item.topicText === queryOptions.topic)
                : null
            }
            getOptionLabel={(option) => option.topicText}
            renderInput={(params) => <TextField {...params} label="Topic" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                topic: value ? value.topicText : undefined,
              }));
            }}
          />
          <Autocomplete
            options={filterData ? filterData.difficultyList : []}
            value={
              filterData
                ? filterData.difficultyList.find(
                    (item) => item.difficultyValue === queryOptions.difficulty
                  )
                : null
            }
            getOptionLabel={(option) => option.difficultyText}
            renderInput={(params) => <TextField {...params} label="Difficulty" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                difficulty: value ? value.difficultyValue : undefined,
              }));
            }}
          />
        </Stack>
      </Popover>
    </Stack>
  );
};

export default QuizzesAnalytics;
