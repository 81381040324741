import { useEffect, useState } from "react";

import { LIST as CONTENT_STATUS_LIST } from "../../../constants/contentStatuses";

import axios from "axios";
import moment from "moment";
import ColorHash from "color-hash";
import { useAppSelector } from "../../../hooks/redux";

import {
  Grid,
  Stack,
  Typography,
  Select,
  SelectChangeEvent,
  MenuItem,
  Chip,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import { IChannel } from "../../../types/leaderboard";
import {
  IAnsweredCorrectQuizzes,
  IChannelAnalyticsResponse,
  IGeneralAnalytics,
} from "../../../types/analytics";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import { ContentAnalyticsHelper } from "../../../constants/helpers";
import Helper from "../../../components/Helper";
import CustomPaper from "../../../components/CustomPaper";
import { useTheme } from "@mui/material/styles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const ContentAnalytics: React.FunctionComponent<{}> = () => {
  var colorHash = new ColorHash({
    hue: { min: 30, max: 45 },
    saturation: [0.8, 0.9],
    lightness: [0.5, 0.65],
  });

  const languages = useAppSelector((state) => state.classifiers.languages);

  const [channels, setChannels] = useState<IChannel[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<number>(0);

  const [isGeneralLoading, setGeneralLoading] = useState(false);
  const [generalAnalytics, setGeneralAnalytics] = useState<IGeneralAnalytics>();
  const [isChannelLoading, setChannelLoading] = useState(false);
  const [channelAnalytics, setChannelAnalytics] =
    useState<IChannelAnalyticsResponse>();

  const [answeredCorrectQuizzes, setAnsweredCorrectQuizzes] = useState<
    IAnsweredCorrectQuizzes[]
  >([]);
  const [contentRatio, setContentRation] = useState<
    { name: string; ratio: number }[]
  >([]);

  useEffect(() => {
    axios
      .get<{ workspaceChannels: IChannel[] }>("/Alytics/GetChannels")
      .then((res) => {
        setChannels(res.data.workspaceChannels);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    axios
      .get<{ answeredCorrectQuizzes: IAnsweredCorrectQuizzes[] }>(
        "/Alytics/GetAnsweredCorrectQuizzes",
        { params: { channelId: selectedChannel } }
      )
      .then((res) => {
        setAnsweredCorrectQuizzes(res.data.answeredCorrectQuizzes);
      })
      .catch((err) => {
        console.log("error:", err);
      });

    axios
      .get<{ contentName: string[]; contentRatio: number[] }>(
        "/Alytics/GetContentDistributionRatio",
        { params: { channelId: selectedChannel } }
      )
      .then((res) => {
        let mapped: { name: string; ratio: number }[] = [];
        res.data.contentName.forEach((element, index) => {
          const ratio = res.data.contentRatio[index];
          mapped.push({
            name: element,
            ratio: ratio ? ratio : 0,
          });
        });
        setContentRation(mapped);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, [selectedChannel]);

  useEffect(() => {
    setGeneralLoading(true);
    setChannelLoading(true);
    axios
      .get<IGeneralAnalytics>("/Alytics/GetGeneralAnalytics", {
        params: {
          channelId: selectedChannel,
        },
      })
      .then((res) => {
        setGeneralAnalytics(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setGeneralLoading(false);
      });

    axios
      .get<IChannelAnalyticsResponse>("/Alytics/GetChannelAnalytics", {
        params: {
          channelId: selectedChannel,
        },
      })
      .then((res) => {
        setChannelAnalytics(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setChannelLoading(false);
      });
  }, [selectedChannel]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Content",
      sortable: false,
      flex: 1,
      minWidth: 350,
    },
    {
      field: "languageId",
      headerName: "Language",
      sortable: false,

      renderCell: (params) => {
        const lang = languages.find(
          (language) => language.languageId === params.value
        );

        if (!lang) {
          return null;
        }
        return <Chip label={lang.languageName} />;
      },
      minWidth: 130,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,

      renderCell: (params) => {
        const status = CONTENT_STATUS_LIST.find(
          (status) => status.value === params.value
        );
        if (!status) {
          return null;
        }

        return <Chip label={status.name} />;
      },
      minWidth: 100,
    },
    {
      field: "lastDistribution",
      headerName: "Last distribution",
      renderCell: (params) => {
        if (!params.value) {
          return "";
        }
        return moment.utc(params.value).local().format("L LT");
      },
      sortable: false,
      minWidth: 210,
    },
    {
      field: "quizzesSent",
      headerName: "Sent lessons",
      sortable: false,
      minWidth: 160,
    },
    {
      field: "quizzesCompleted",
      headerName: "Completed lessons",
      sortable: false,
      renderCell: (params) => {
        return `${params.value} (${params.row.quizzesCompletedPercent} %)`;
      },
      minWidth: 240,
    },
    {
      field: "correctAnswers",
      headerName: "Correct lessons",
      sortable: false,
      renderCell: (params) => {
        return `${params.value} (${params.row.correctAnswersPercent} %)`;
      },
      minWidth: 200,
    },
  ];

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1}>
        <Typography component="h1" variant="h2">
          Course analytics
        </Typography>
        <Select
          id="team-select"
          value={selectedChannel.toString()}
          label="Team"
          onChange={(event: SelectChangeEvent) => {
            setSelectedChannel(parseInt(event.target.value));
          }}
          variant="filled"
          disableUnderline
        >
          {channels.map((channel) => (
            <MenuItem key={channel.id} value={channel.id}>
              {channel.channelName}
            </MenuItem>
          ))}
        </Select>
        <Helper title={<ContentAnalyticsHelper />} />
      </Stack>

      <div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <GeneralAnalyticsCard
              title="Users Completed Onboarding"
              primaryText={`${generalAnalytics?.completedOnboarding}/${generalAnalytics?.allUsersWithOnboardingSent} (${generalAnalytics?.completedOnboardingPercent}%)`}
              loading={isGeneralLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <GeneralAnalyticsCard
              title="Weekly Active Users"
              primaryText={`${generalAnalytics?.weeklyActiveUsers}/${generalAnalytics?.completedOnboarding} (${generalAnalytics?.weeklyActiveUsersPercent}%)`}
              loading={isGeneralLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <GeneralAnalyticsCard
              title="Weekly Lessons Completed"
              primaryText={`${generalAnalytics?.weekyQuizzesCompleted}/${generalAnalytics?.weeklyQuizzesSent} (${generalAnalytics?.weeklyQuizzesCompletedPercent}%)`}
              loading={isGeneralLoading}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} lg={8}>
            <Typography variant="h3">Completed and correct lessons</Typography>
            <CustomPaper
              sx={{
                padding: 2,
              }}
            >
              <Line
                style={{ maxHeight: 400 }}
                options={{
                  responsive: true,
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        precision: 0,
                      },
                    },
                  },
                }}
                data={{
                  labels: answeredCorrectQuizzes.map((item) =>
                    moment(item.month, "MM").format("MMMM")
                  ),
                  datasets: [
                    {
                      label: "Completed lessons",
                      data: answeredCorrectQuizzes.map(
                        (item) => item.answeredQuestions
                      ),
                      borderColor: "rgb(244, 121, 35)",
                      backgroundColor: "rgba(244, 121, 35, 0.5)",
                      tension: 0.4,
                    },
                    {
                      label: "Correct lessons",
                      data: answeredCorrectQuizzes.map(
                        (item) => item.correctAnswers
                      ),
                      borderColor: "rgb(245, 212, 188)",
                      backgroundColor: "rgba(245, 212, 188, 0.5)",
                      tension: 0.4,
                    },
                  ],
                }}
              />
            </CustomPaper>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="h3">Sent lessons</Typography>
            <CustomPaper
              sx={{
                padding: 2,
              }}
            >
              <Pie
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "bottom",
                      align: "center",
                    },
                    tooltip: {
                      callbacks: {
                        label(tooltipItem) {
                          return `${tooltipItem.label}: ${tooltipItem.formattedValue}%`;
                        },
                      },
                    },
                  },
                }}
                data={{
                  labels: contentRatio.map((item) => item.name),
                  datasets: [
                    {
                      label: "Dataset 1",
                      data: contentRatio.map((item) => item.ratio),
                      backgroundColor: contentRatio.map((item) =>
                        colorHash.hex(item.name)
                      ),
                    },
                  ],
                }}
              />
            </CustomPaper>
          </Grid>
        </Grid>
      </div>

      <DataGrid
        getRowId={(row) => row.name}
        rows={channelAnalytics ? channelAnalytics.channelAnalytics : []}
        rowCount={channelAnalytics?.numberOfEntries}
        columns={columns}
        loading={isChannelLoading}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        pageSizeOptions={[100]}
      />
    </Stack>
  );
};

interface IGeneralAnalyticsCard {
  title: string;
  primaryText?: string;
  loading?: boolean;
}

export const GeneralAnalyticsCard: React.FunctionComponent<
  IGeneralAnalyticsCard
> = ({ title, primaryText, loading }) => {
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <CustomPaper>
      <Stack alignItems="center">
        {loading ? (
          <Skeleton
            width={180}
            height={60}
            sx={{
              marginTop: 5,
              marginBottom: 2,
            }}
          />
        ) : (
          <Typography
            variant={lgDown ? "h3" : "h1"}
            component="h2"
            sx={{
              paddingTop: 5,
              paddingBottom: 2,
            }}
          >
            {primaryText}
          </Typography>
        )}
        <Stack
          sx={{
            width: "100%",
          }}
          alignItems="flex-end"
        >
          {loading ? (
            <Skeleton
              width={200}
              height={30}
              sx={{
                marginBottom: 2,
                marginRight: 3,
              }}
            />
          ) : (
            <Typography
              variant={lgDown ? "subtitle2" : "subtitle1"}
              sx={{
                paddingBottom: 2,
                paddingRight: 3,
              }}
            >
              {title}
            </Typography>
          )}
        </Stack>
      </Stack>
    </CustomPaper>
  );
};

export default ContentAnalytics;
