import { useEffect, useState } from "react";

import axios from "axios";

import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  IKnowledgeTrends,
  IKnowledgeTrendsFilters,
} from "../../../types/analytics";

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { LIST as DIFFICULTIES_LIST } from "../../../constants/dificulties";

import { TrendsAnalyticsHelper } from "../../../constants/helpers";
import Helper from "../../../components/Helper";
import CustomPaper from "../../../components/CustomPaper";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const TrendsAnalytics: React.FunctionComponent<{}> = () => {
  const [filterData, setFilterData] = useState<IKnowledgeTrendsFilters>();
  const [selectedChannel, setSelectedChannel] = useState<number>(0);
  const [selectedContent, setSelectedContent] = useState<number>(0);
  const [data, setData] = useState<IKnowledgeTrends>();

  const [isFiltersLoading, setIsFiltersLoading] = useState<boolean>(true);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  useEffect(() => {
    axios
      .get<IKnowledgeTrendsFilters>("/Alytics/GetKnowledgeTrendsFilter")
      .then((res) => {
        setFilterData(res.data);
        if (res.data.contentList[0]) {
          setSelectedContent(res.data.contentList[0].id);
        }
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setIsFiltersLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedContent !== 0) {
      setIsDataLoading(true);
      axios
        .get<IKnowledgeTrends>("/Alytics/GetKnowledgeTrends", {
          params: {
            questionCategoryId: selectedContent,
            channelId: selectedChannel,
          },
        })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log("error:", err);
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    }
  }, [selectedChannel, selectedContent]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography component="h1" variant="h2">
              Trends in Team
            </Typography>
            <Select
              variant="filled"
              value={selectedChannel.toString()}
              onChange={(event: SelectChangeEvent) => {
                setSelectedChannel(parseInt(event.target.value));
              }}
              disableUnderline
            >
              {filterData?.channelList.map((channel) => (
                <MenuItem key={channel.id} value={channel.id}>
                  {channel.teamName}
                </MenuItem>
              ))}
            </Select>
            <Helper title={<TrendsAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>
      <Typography variant="h3">Knowledge level in different topics</Typography>
      <CustomPaper sx={{ padding: 2 }}>
        {!isFiltersLoading && (
          <FormControl>
            <InputLabel id="content-select">Course</InputLabel>
            <Select
              labelId="content-select"
              id="content-select"
              value={selectedContent.toString()}
              label="Course"
              onChange={(event: SelectChangeEvent) => {
                setSelectedContent(parseInt(event.target.value));
              }}
            >
              {filterData?.contentList.map((content) => (
                <MenuItem key={content.id} value={content.id}>
                  {content.contentName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {isDataLoading || isFiltersLoading ? (
          <Stack direction="row" justifyContent="center" flex={1}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {data && data.topics.length > 0 ? (
              <Radar
                style={{ maxHeight: 600 }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "left",
                    },
                    tooltip: {
                      callbacks: {
                        label(tooltipItem) {
                          return `${tooltipItem.label}: ${tooltipItem.formattedValue}%`;
                        },
                      },
                    },
                  },
                  scales: {
                    r: {
                      beginAtZero: true,
                      suggestedMin: 70,
                      max: 100,
                      ticks: {
                        precision: 0,
                        callback: function (value, index, ticks) {
                          return value + "%";
                        },
                      },
                    },
                  },
                }}
                data={{
                  labels: data?.topics,
                  datasets: data
                    ? data.difficultyTrends.map((trend) => {
                        const difficulty = DIFFICULTIES_LIST.find(
                          (d) => d.value === trend.difficultyTier
                        );

                        return {
                          label: difficulty?.name,
                          data: trend.trendsData,
                          borderColor: `rgb(${difficulty?.color[0]}, ${difficulty?.color[1]}, ${difficulty?.color[2]})`,
                          backgroundColor: `rgba(${difficulty?.color[0]}, ${difficulty?.color[1]}, ${difficulty?.color[2]}, 0.15)`,
                        };
                      })
                    : [],
                }}
              />
            ) : (
              <Typography align="center">
                No data to show, try switching team or content
              </Typography>
            )}
          </>
        )}
      </CustomPaper>
    </Stack>
  );
};

export default TrendsAnalytics;
