import { GridRenderCellParams } from "@mui/x-data-grid";

import { Tooltip } from "@mui/material";

export function renderTooltipCell(params: GridRenderCellParams) {
  return (
    <Tooltip title={params.formattedValue ? params.formattedValue : params.value}>
      <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {params.formattedValue ? params.formattedValue : params.value}
      </span>
    </Tooltip>
  );
}
