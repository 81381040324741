import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";

import _ from "lodash";

import {
  Grid,
  Stack,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  SelectChangeEvent,
  Breadcrumbs,
  Link,
  Alert,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@mui/material";
import { ITeamEdit, ITeamEditData } from "../../types/team";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { toast } from "react-toastify";

import moment from "moment";

import { track } from "@amplitude/analytics-browser";
import { LoadingButton } from "@mui/lab";
import usePrompt from "../../hooks/router";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import WarningIcon from "@mui/icons-material/Warning";
import InfoCircle from "../../components/Icons/InfoCircle";
import Chevron from "../../components/Icons/Chevron";
import { grey } from "@mui/material/colors";
import { setRun } from "../../features/tour";

const TeamsEdit = () => {
  const { channelId, languageId } = useParams<{
    channelId: string;
    languageId: string;
  }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tour = useAppSelector((state) => state.tour);
  const languages = useAppSelector((state) => state.classifiers.languages);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [originalResponse, setOriginalResponse] = useState<ITeamEdit>();
  const [response, setResponse] = useState<ITeamEdit>();

  const handleChange = (event: SelectChangeEvent) => {
    navigate(`/teams/${channelId}/${event.target.value as string}`);
  };

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<ITeamEdit>(`/Teams/GetEditTeams`, {
        params: {
          channelId,
          languageId,
        },
      })
      .then((res) => {
        setOriginalResponse(res.data);
        setResponse(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [channelId, languageId]);

  useEffect(() => {
    loadData();
    track("OpenedTeamEdit");
  }, [loadData]);

  usePrompt(
    "You have unsaved changes, are you sure you want to leave?",
    !_.isEqual(originalResponse, response),
    () => {}
  );

  const columns: GridColDef[] = [
    {
      field: "categories",
      headerName: "Course",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<ITeamEditData>) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Link
              component={RouterLink}
              to={`/courses/${params.row.questionCategoryId}`}
              color={params.row.disabledLessons ? grey[500] : undefined}
            >
              <Typography>{params.value}</Typography>
            </Link>
            {params.row.lowSocialLessons && (
              <Tooltip title="Low Social Lessons: Engage employees with Friday social questions to generate sufficient lessons for distribution.">
                <WarningIcon color="warning" />
              </Tooltip>
            )}
            {params.row.quizzesUnavailable && !params.row.disabledLessons && (
              <Tooltip title="Course Lacks Lessons: Add more lessons or replace this course to ensure continuous learning for your team.">
                <WarningIcon color="error" />
              </Tooltip>
            )}
            {params.row.disabledLessons && (
              <Tooltip title="This course cannot be scheduled because no lessons are enabled.">
                <WarningIcon color="error" />
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
    ...moment.weekdays().map((value) => {
      return {
        field: value.toLowerCase(),
        headerName: value,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Checkbox
              checked={params.value}
              disabled={
                (params.row.disabledLessons || params.row.quizzesUnavailable) &&
                !params.value
              }
              onChange={(event) => {
                setResponse((prevState) => {
                  if (!prevState) {
                    return undefined;
                  }
                  return {
                    ...prevState,
                    teamsEditData: prevState?.teamsEditData.map((e) =>
                      e.channelCategoryId === params.id
                        ? {
                            ...e,
                            [value.toLowerCase()]: event.target.checked,
                          }
                        : event.target.checked
                        ? {
                            ...e,
                            [value.toLowerCase()]: false,
                          }
                        : e
                    ),
                  };
                });
              }}
            />
          );
        },
      };
    }),
  ];

  const onSave = () => {
    setIsSubmitting(true);

    axios
      .post("/Teams/UpdateEditTeams", {
        channelId,
        languageId,
        teamContentSettings: response?.teamsEditData,
        showTop10Leaderboard: response?.showTop10Leaderboard,
      })
      .then((res) => {
        track("ChangedTeamSettings");
        setOriginalResponse(response);
        toast("Settings saved successfully", {
          type: "success",
        });
      })
      .catch((err) => {
        console.log("err:", err);
        toast("There was an issue saving settings", {
          type: "error",
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (response && tour.index === 11) {
      setTimeout(() => {
        dispatch(setRun(true));
      }, 1000);
    }
  }, [tour, response]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography component="h1" variant="h2">
            Schedule Courses
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={
              <Chevron
                width={20}
                height={20}
                style={{
                  transform: "rotate(90deg)",
                }}
              />
            }
          >
            <Link
              component={RouterLink}
              to={"/teams"}
              underline="none"
              color="inherit"
            >
              {response?.channelName}
            </Link>
            <Typography variant="inherit">Edit</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item>
          <Select
            labelId="language-select-label"
            value={languageId}
            name="languageId"
            onChange={handleChange}
          >
            {languages.map((language) => (
              <MenuItem key={language.languageId} value={language.languageId}>
                {language.languageName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <Stack spacing={2} className="team-course-schedule">
        <DataGrid
          getRowId={(row) => row.channelCategoryId}
          rows={response ? response.teamsEditData : []}
          columns={columns}
          hideFooterPagination
          loading={isLoading}
          autoHeight
          disableRowSelectionOnClick
          disableColumnMenu
          hideFooter
        />

        <Stack
          direction="row"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Stack>
            <FormControlLabel
              control={
                <Switch
                  id="showTop10Leaderboard"
                  checked={response?.showTop10Leaderboard || false}
                  onChange={() => {
                    setResponse((prevState) => {
                      if (!prevState) {
                        return undefined;
                      }
                      return {
                        ...prevState,
                        showTop10Leaderboard: !prevState.showTop10Leaderboard,
                      };
                    });
                  }}
                />
              }
              label="Limit leaderboard to top 10"
            />

            <Alert
              variant="outlined"
              icon={<InfoCircle />}
              style={{
                border: 0,
                alignItems: "center",
                padding: 0,
              }}
            >
              Checked courses are delivered at 13:00 on selected days.
            </Alert>
          </Stack>

          <LoadingButton
            loading={isSubmitting}
            onClick={onSave}
            variant="contained"
            disabled={_.isEqual(originalResponse, response)}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TeamsEdit;
