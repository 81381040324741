import { TRoute, RouteType } from "../types/route";

import ROLES from "../constants/roles";

import Teams from "../views/hub/Teams";
import TeamsEdit from "../views/hub/TeamsEdit";
import Content from "../views/hub/Content";
import ContentEdit from "../views/Content/Edit";
import Access from "../views/hub/Access";
import Leaderboard from "../views/hub/Leaderboard";
import ContentAnalytics from "../views/hub/analytics/Content";
import EmployeesAnalytics from "../views/hub/analytics/Employees";
import QuizzesAnalytics from "../views/hub/analytics/Quizzes";
import TrendsAnalytics from "../views/hub/analytics/Trends";
import Challenges from "../views/hub/Challenges";
import ChallengesAnalytics from "../views/hub/analytics/Challenges";
import Licenses from "../views/hub/Licenses";
import Announcements from "../views/hub/Announcements";
import Tests from "../views/hub/Tests";
import TestsAnalytics from "../views/hub/analytics/Tests";

import BookOpen from "../components/Icons/BookOpen";
import Users from "../components/Icons/Users";
import Trophy from "../components/Icons/Trophy";
import ChartBar from "../components/Icons/ChartBar";
import BellRing from "../components/Icons/BellRing";
import Analytics from "../components/Icons/Analytics";
import CommentQuestion from "../components/Icons/CommentQuestion";
import ChartLine from "../components/Icons/ChartLine";
import CreditCardFront from "../components/Icons/CreditCardFront";
import Key from "../components/Icons/Key";
import QuestionCircle from "../components/Icons/QuestionCircle";
import NoteClipboard from "../components/Icons/NoteClipboard";

// INFO: path /docs is reserved route for documentation and handled as static content

export const routes: TRoute[] = [
  {
    type: RouteType.Menu,
    path: "/courses",
    element: Content,
    name: "Courses",
    icon: <BookOpen />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Menu,
    path: "/teams",
    element: Teams,
    name: "Schedule Courses",
    icon: <Users />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Basic,
    path: "/teams/:channelId/:languageId",
    element: TeamsEdit,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Basic,
    path: "/courses/:questionCategoryTranslationsId",
    element: ContentEdit,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },

  {
    type: RouteType.Menu,
    path: "/challenges",
    element: Challenges,
    name: "Challenges",
    icon: <Trophy />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Menu,
    path: "/tests",
    element: Tests,
    name: "Tests",
    icon: <NoteClipboard />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Menu,
    path: "/leaderboard",
    element: Leaderboard,
    name: "Leaderboard",
    icon: <ChartBar />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Menu,
    path: "/announcements",
    element: Announcements,
    name: "Announcements",
    icon: <BellRing />,
    allowedRoles: [ROLES.WorkspaceAdmin, ROLES.IntervyAdmin],
  },
  {
    type: RouteType.Group,
    name: "Analytics",
    icon: <Analytics />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
    routes: [
      {
        type: RouteType.Menu,
        path: "/analytics/courses",
        element: ContentAnalytics,
        name: "Courses",
        icon: <BookOpen />,
        allowedRoles: [
          ROLES.WorkspaceAdmin,
          ROLES.IntervyAdmin,
          ROLES.SubscriptionAdmin,
        ],
      },
      {
        type: RouteType.Menu,
        path: "/analytics/employees",
        element: EmployeesAnalytics,
        name: "Employees",
        icon: <Users />,
        allowedRoles: [
          ROLES.WorkspaceAdmin,
          ROLES.IntervyAdmin,
          ROLES.SubscriptionAdmin,
        ],
      },
      {
        type: RouteType.Menu,
        path: "/analytics/quizzes",
        element: QuizzesAnalytics,
        name: "Lessons",
        icon: <CommentQuestion />,
        allowedRoles: [
          ROLES.WorkspaceAdmin,
          ROLES.IntervyAdmin,
          ROLES.SubscriptionAdmin,
        ],
      },
      {
        type: RouteType.Menu,
        path: "/analytics/trends",
        element: TrendsAnalytics,
        name: "Trends",
        icon: <ChartLine />,
        allowedRoles: [
          ROLES.WorkspaceAdmin,
          ROLES.IntervyAdmin,
          ROLES.SubscriptionAdmin,
        ],
      },
      {
        type: RouteType.Menu,
        path: "/analytics/challenges",
        element: ChallengesAnalytics,
        name: "Challenges",
        icon: <Trophy />,
        allowedRoles: [
          ROLES.WorkspaceAdmin,
          ROLES.IntervyAdmin,
          ROLES.SubscriptionAdmin,
        ],
      },
      {
        type: RouteType.Menu,
        path: "/analytics/tests",
        element: TestsAnalytics,
        name: "Tests",
        icon: <NoteClipboard />,
        allowedRoles: [
          ROLES.WorkspaceAdmin,
          ROLES.IntervyAdmin,
          ROLES.SubscriptionAdmin,
        ],
      },
    ],
  },
  {
    type: RouteType.Menu,
    path: "/licenses",
    element: Licenses,
    name: "License Management",
    icon: <CreditCardFront />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },
  {
    type: RouteType.Menu,
    path: "/access",
    element: Access,
    name: "Access",
    icon: <Key />,
    allowedRoles: [
      ROLES.WorkspaceAdmin,
      ROLES.IntervyAdmin,
      ROLES.SubscriptionAdmin,
    ],
  },

  {
    name: "Help Center",
    type: RouteType.Link,
    link: "/docs",
    icon: <QuestionCircle />,
  },
];
