import { useCallback, useEffect, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import axios from "axios";

import { Grid, Typography, Button, Stack, Switch, IconButton } from "@mui/material";

import { useAppDispatch } from "../../hooks/redux";
import { openModal } from "../../features/modals";
import MODALS from "../../constants/modals";
import { ITestResponse } from "../../types/test";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import TEST_STATUSES from "../../constants/testStatuses";
import { TestsHelper } from "../../constants/helpers";
import Helper from "../../components/Helper";
import Edit from "../../components/Icons/Edit";
import Analytics from "../../components/Icons/Analytics";
import Plus from "../../components/Icons/Plus";

const Tests: React.FunctionComponent<{}> = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ITestResponse[]>([]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<{
        tests: ITestResponse[];
      }>(`/Test/GetTests`)
      .then((res) => {
        setData(res.data.tests);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns: GridColDef[] = [
    {
      field: "questionCategoryName",
      headerName: "Course",
      sortable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "channelName",
      headerName: "Team",
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "questionCount",
      headerName: "Number of Questions",
      sortable: false,
      width: 290,
    },
    {
      field: "startDate",
      headerName: "Active from",
      sortable: false,
      valueFormatter: (params) => {
        return moment.utc(params.value as string).format("L");
      },
      minWidth: 140,
    },
    {
      field: "endDate",
      headerName: "Active till",
      sortable: false,
      valueFormatter: (params) => {
        return moment.utc(params.value as string).format("L");
      },
      minWidth: 140,
    },
    {
      field: "correctAnswersCriteria",
      headerName: "Correct answers goal",
      sortable: false,
      valueFormatter: (params) => {
        return `${params.value}%`;
      },
      width: 260,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      renderCell: (params) => {
        return <Switch checked={params.value === TEST_STATUSES.Visible} disabled size="small" />;
      },
    },

    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <ReactRouterLink
              to={`/analytics/tests`}
              state={{
                testId: params.row.id,
              }}
            >
              <IconButton>
                <Analytics />
              </IconButton>
            </ReactRouterLink>
            <IconButton
              onClick={() => {
                dispatch(
                  openModal({
                    type: MODALS.CreateTest,
                    initialValues: params.row,
                    callback: loadData,
                  })
                );
              }}
            >
              <Edit />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Tests
            </Typography>
            <Helper title={<TestsHelper />} />
          </Stack>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<Plus color="#fff" />}
            onClick={() => {
              dispatch(
                openModal({
                  type: MODALS.CreateTest,
                  callback: loadData,
                })
              );
            }}
          >
            Create test
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        loading={isLoading}
        getRowId={(row) => row.id}
        rows={data}
        columns={columns}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        pageSizeOptions={[100]}
      />
    </Stack>
  );
};

export default Tests;
