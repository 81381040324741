enum CONTENT_STATUSIES {
  Stopped = 0,
  Running = 1,
  Deleted = 2,
}

export const LIST = [
  {
    name: "Stopped",
    value: CONTENT_STATUSIES.Stopped,
  },
  {
    name: "Running",
    value: CONTENT_STATUSIES.Running,
  },
  {
    name: "Deleted",
    value: CONTENT_STATUSIES.Deleted,
  },
];

export default CONTENT_STATUSIES;
